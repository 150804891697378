.mainimgclass {
  object-fit: cover;
  background-size: cover;
}
.imgcontpar {
  height: 155px;
  width: 130px;
}
.flexjustify {
  justify-content: space-between;
}
.adjustwidth {
  max-width: 400px;
}
@media screen and (max-width: 992px) {
  .flexjustify {
    justify-content: center;
  }
  .flexdir {
    flex-direction: column;
  }
  .adjustwidth {
    max-width: 100%;
  }
  .imgcontpar {
    width: 240px;
  }
}
@media screen and (max-width: 769px) {
  .imgcontpar {
    width: 100%;
    height: 100%;
  }
}
