.react-multi-carousel-track {
  margin-left: 0 !important;
}
.react-multiple-carousel__arrow {
  z-index: 700;
}
.mainslparent {
  object-fit: cover;
  position: relative;
  height: 88vh;
  margin-top: 100px;
}
.sloverlay {
  top: 0;
  position: absolute;
  height: 88vh;
  background: #000;
  opacity: 0.4;
  z-index: 100;
  width: 100%;
}
.imagetextslider {
  position: absolute;
  font-size: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-85%, -50%);
  color: #fff;
  font-weight: 900;
  line-height: 90px;
  z-index: 200;
  filter: drop-shadow(2px 4px 6px black);
}
@media screen and (max-width: 1200px) {
  .mainslparent {
    height: 45vh;
  }
  .sloverlay {
    height: 45vh;
  }
}
@media screen and (max-width: 768px) {
  .mainslparent {
    height: 45vh;
  }
  .sloverlay {
    height: 45vh;
  }
  .imagetextslider {
    line-height: 55px;
    font-size: 50px;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 640px) {
  .mainslparent {
    height: 30vh;
  }
  .sloverlay {
    height: 30vh;
  }
  .imagetextslider {
    line-height: 55px;
    font-size: 50px;
  }
}
@media screen and (max-width: 480px) {
  .react-multiple-carousel__arrow {
    top: 213px;
  }
}
