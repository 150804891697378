.heightimage {
  height: 600px;
}
.conentpadding {
  padding: 3rem;
}
#partner1 {
  object-fit: cover !important;
}

@media screen and (max-width: 992px) {
  .flexcol {
    flex-direction: column;
  }
  .colrevers {
    flex-direction: column-reverse;
  }
  .heightimage {
    height: 600px;
  }
  .conentpadding {
    padding: 2rem;
  }
  #partner1 {
    object-fit: fill !important;
  }
}
@media screen and (max-width: 769px) {
  .heightimage {
    height: 450px;
  }
  .conentpadding {
    padding: 1rem;
  }
}
/* @media screen and (max-width: 567px) {
  .heightimage {
    height: 50px;
  }
} */
